import {Prop, Vue} from 'vue-property-decorator';
import {Component} from 'vue-property-decorator';
import Box from '@/commoncomponents/Box/Box.vue';
import Checkbox from '@/commoncomponents/Checkbox.vue';

@Component({
  name: 'DirectAccessBox',
  components: {Checkbox, Box}
})
export default class DirectAccessBox extends Vue {

  @Prop({type: Boolean, default: false}) readonly isChecked: boolean = false;

  handleCheckbox(value: boolean) {
    this.$emit('change', value);
  }
}
